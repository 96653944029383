import React from 'react';

interface Props {
  children: React.ReactNode;
}

const Layout: React.FC<Props> = ({ children }: Props) => {
  return <main className="h-full selection:bg-gray-200">{children}</main>;
};

export { Layout };
