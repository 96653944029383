import React from 'react';

interface LogoProps {
  width?: number;
  height?: number;
}

const Logo: React.FC = (props: LogoProps = { width: 131, height: 21 }) => {
  return (
    <svg
      width={props.width || '131'}
      height={props.height || '21'}
      viewBox="0 0 131 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M40.1878 4.56308L41.8192 0.605135H22.1047L17.6838 4.56308H40.1878Z"
        fill="white"
      />
      <path
        d="M38.5564 8.52103H13.2628L8.8419 12.479H36.925L38.5564 8.52103Z"
        fill="white"
      />
      <path
        d="M35.2936 16.4369H4.42095L0 20.3949H33.6622L35.2936 16.4369Z"
        fill="white"
      />
      <path
        d="M128.833 8.44561H117.867V4.14629H129.431V0.880982H113.839V20.0919H128.833L130.086 16.9643L117.867 16.8266V11.7109H128.833V8.44561Z"
        fill="white"
      />
      <path
        d="M91.9004 14.4048C92.499 17.8878 95.1929 20.5 101.016 20.5C107.247 20.5 109.805 17.8333 109.805 14.5952C109.805 11.8469 108.662 9.67007 101.479 8.39116C97.6146 7.71088 96.8255 7.03061 96.8255 5.7517C96.8255 4.28231 98.1861 3.52041 100.581 3.52041C104.036 3.52041 104.717 4.93537 105.043 5.9966H109.07C108.853 3.87415 107.166 0.5 100.689 0.5C96.2541 0.5 92.7167 2.45918 92.7167 6.15986C92.7167 8.7449 93.9956 10.7857 100.39 11.8741C104.717 12.6088 105.669 13.5068 105.669 15.0034C105.669 16.4184 104.608 17.5068 101.37 17.5068C97.9956 17.5068 96.5534 16.2551 95.9548 14.4048H91.9004Z"
        fill="white"
      />
      <path
        d="M83.769 15.2619L85.8098 20.1055H90.1908L81.8914 0.894571H76.803L68.8846 20.1055H73.0479L74.9799 15.2619H83.769ZM76.0683 11.9966C77.6738 7.9694 78.7894 4.92178 79.1976 3.56124H79.2248C79.6874 4.9762 80.6397 7.28913 82.5989 11.9966H76.0683Z"
        fill="white"
      />
      <path
        d="M65.058 8.4592H54.092V4.15988H65.6566V0.894574H50.0648V20.1055H65.058L66.3112 16.9779L54.092 16.8402V11.7245H65.058V8.4592Z"
        fill="white"
      />
    </svg>
  );
};

export { Logo };
