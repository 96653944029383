import React from 'react';

interface Props {
  children?: React.ReactNode;
}

const SEO: React.FC<Props> = ({ children }: Props) => {
  return (
    <>
      <title id="title">
        EASE // Enterprise Awareness &amp; Situational Exceptions
      </title>
      <meta
        id="description"
        name="description"
        content="A data-integration platform for real-time operational responses. Collect key data insights, predict customer behavior, and direct confidently."
      />
      <link rel="manifest" href="manifest.json" />
      <link id="favicon" rel="icon" href="favicon.ico" />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="favicon-16x16.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="apple-touch-icon.png"
      />
      <link rel="mask-icon" href="safari-pinned-tab.svg" color="#74e568" />
      {children && children}
    </>
  );
};

export { SEO };
