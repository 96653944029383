import React from 'react';

interface Props {
  children: React.ReactNode;
  className?: string;
}

const Cell: React.FC<Props> = ({ children, className }: Props) => {
  return (
    <div className={`max-w-[1200px] mx-auto relative z-10 ${className}`}>
      {children}
    </div>
  );
};

export { Cell };
