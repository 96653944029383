import React from 'react';

interface Props {
  children: React.ReactNode;
  className?: string;
  id?: string;
}

const Row: React.FC<Props> = ({ children, className, ...rest }: Props) => {
  return (
    <section
      className={`px-6 xs:px-10 md:px-20 relative overflow-hidden ${className}`}
      {...rest}
    >
      {children}
    </section>
  );
};

export { Row };
